<template>
    <div>
        <div v-for="document in row.item.documents">
            <div>
                <a target="_blank"
                   @click.prevent="$root.openDocument(document.name, 'simple')"
                   :href="$root.getDocumentUrl(document.name, 'simple')"
                >{{ document.name }}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
    },
}
</script>